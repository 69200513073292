<template>
  <v-content id="home">
    <router-view />
  </v-content>
</template>

<script>
export default {
  name: 'CoreView'
}
</script>
